import { ReactElement } from 'react';
import { Location } from 'pages/locations';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import Link from './Link';
import NewLocationDetailsCard from './NewLocationDetailsCard';

type LocationCardProps = {
  location: Location;
  className?: string;
  activeLocation?: Location | null;
};

export default function NewLocationCard({
  location,
  className = '',
  activeLocation,
}: LocationCardProps): ReactElement {
  return (
    <div
      className={`flex py-6 px-10 lg:px-0 lg:pl-16 w-full border-b-2 flex-col xs:flex-row lg:hover:bg-muted ${
        activeLocation == location ? 'bg-muted' : ''
      } ${className} `}
    >
      <div className="w-full mb-2 xs:w-1/2">
        <NewLocationDetailsCard location={location} size={'lg'} />
      </div>
      <div className="text-center  w-full xs:w-1/2">
        <div className="flex flex-col text-center w-full space-y-6">
          <ScheduleAppointmentCta
            location={location}
            colorScheme="Primary"
            wrapperClasses=""
            text="Schedule an Eye Exam"
            classNames="flex flex-grow w-full justify-center remove-cta-padding"
          />
          <Link slug={`locations/${location?.slug}`}>
            <a className="block bold text-tertiary-actual text-sm lg:text-lg mt-2 md:mt-6">
              View Location Details
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
