import React, { useEffect, useCallback, useState, ReactElement } from 'react';
// import { LocationClient, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import UseMyLocation from '@material-ui/icons/MyLocationOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { geoSuggestionsResponse } from 'types/common';

export interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: {
      offset: number;
      length: number;
    }[];
  };
  lat: number;
  lng: number;
}

const useIconStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const useProgressStyles = makeStyles({
  colorPrimary: {
    backgroundColor: 'rgb(var(--secondary))',
  },
  barColorPrimary: {
    backgroundColor: 'rgb(var(--tertiary))',
  },
});

type LocationAutocompleteProps = {
  onSelect?: (place: PlaceType | null) => void;
  initValue?: PlaceType | null;
  className: string;
  onUseMyLocation?: (coords: GeolocationCoordinates) => void;
  freshPaintToken: string | null | undefined;
  hideEndAdornment?: boolean;
  onFindClick?: (place: PlaceType | null) => void;
};

export default function NewLocationAutocomplete({
  onSelect,
  onUseMyLocation,
  initValue,
  className,
  freshPaintToken,
  hideEndAdornment,
  onFindClick,
  ...props
}: LocationAutocompleteProps): ReactElement {
  const iconClasses = useIconStyles();
  const progressClasses = useProgressStyles();
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<PlaceType[]>([]);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const useMyLocation = (): void => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (onUseMyLocation) {
          onUseMyLocation(position.coords);
        }
        setIsLoading(false);
      },
      () => {
        setError('Could not fetch your location. Please enable location services.');
        setIsLoading(false);
      },
      { timeout: 10000 }
    );
  };

  const fetchAWSPlacePredictions = useCallback(async () => {
    if (inputValue === '') return;

    try {
      const url = `https://freshpaint-hipaa-maps.com/places/v0/indexes/freshpaint/search/suggestions?token=${freshPaintToken}`;
      // const url = `https://places.geo.us-east-1.amazonaws.com/places/v0/indexes/LocationServicePlaceIndex/search/suggestions?key=${process?.env?.NEXT_PUBLIC_AWS_LOCATION}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Text: inputValue,
          MaxResults: 8,
          FilterCountries: ['USA'],
        }),
      });
      const data: geoSuggestionsResponse = await response.json();

      const results = (data.Results || []).map((result) => ({
        place_id: result.PlaceId || '',
        description: result.Text || '',
        structured_formatting: {
          main_text: result.Text || '',
          secondary_text: '',
          main_text_matched_substrings: [{ offset: 0, length: result.Text?.length || 0 }],
        },
        lat: 0,
        lng: 0,
      }));

      setOptions(results);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setError('Error fetching location data.');
    } finally {
      setIsLoading(false);
    }
  }, [inputValue, freshPaintToken]);

  const fetchAWSPlace = async (placeId: string | undefined): Promise<void> => {
    try {
      if (placeId) {
        //`https://freshpaint-hipaa-maps.com/places/v0/indexes/freshpaint/search/position?token=${process?.env?.NEXT_PUBLIC_FRESHPAINT_LOCATION}`;
        // const url = `https://places.geo.us-east-1.amazonaws.com/places/v0/indexes/LocationServicePlaceIndex/places/${placeId}?key=${process?.env?.NEXT_PUBLIC_AWS_LOCATION}`;
        const url = `https://freshpaint-hipaa-maps.com/places/v0/indexes/freshpaint/places/${placeId}?token=${freshPaintToken}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (data?.Place) {
          if (onSelect) {
            onSelect({
              place_id: placeId,
              description: data?.Place?.Label,
              lng: data?.Place?.Geometry?.Point?.[0],
              lat: data?.Place?.Geometry?.Point?.[1],
              structured_formatting: {
                main_text: data.Text || '',
                secondary_text: data.Place?.Region || '',
                main_text_matched_substrings: [{ offset: 0, length: data.Text?.length || 0 }],
              },
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setValue(initValue || null);
  }, [initValue]);

  useEffect(() => {
    fetchAWSPlacePredictions();
  }, [fetchAWSPlacePredictions]);

  const handleFindClick = (): void => {
    if (!inputValue.trim()) {
      setError('Please enter an address');
      return;
    }

    const matchedOption = options.find((option) => option.description === inputValue);

    if (matchedOption) {
      // Select the matched option
      setValue(matchedOption);
      fetchAWSPlace(matchedOption.place_id);
      if (onSelect) {
        onSelect(matchedOption);
      }
      setError('');
    } else {
      setError('No matching address found. Please refine your input.');
    }
  };

  const renderOption = (option: PlaceType): ReactElement => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length])
    );

    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={iconClasses.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams): ReactElement => (
    <div className="flex flex-row space-x-2 w-full md:w-3/4 mx-auto ">
      <TextField
        className="shadow-lg rounded-full bg-white"
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                color="primary"
                aria-label="use my location"
                component="span"
                onClick={useMyLocation}
              >
                <UseMyLocation className="text-tertiary-actual" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: hideEndAdornment ? null : params.InputProps.endAdornment,
          className: 'border-t rounded-full border-gray-200  p-3',
          disableUnderline: true,
        }}
        placeholder="Enter address, city, state, or zip"
        variant="standard"
        fullWidth
      />
      <div className="w-1/4">
        <button
          onClick={() => {
            if (onFindClick) {
              onFindClick(value);
            } else {
              handleFindClick();
            }
          }}
          className={`w-full flex-grow button-text rounded-full border border-teal h-full px-auto max-w-120 md:max-w-200 lg:max-w-300 m-1 hover:border-black hover:bg-neutral bg-white remove-cta-padding`}
        >
          Find
        </button>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <Autocomplete
        {...props}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        value={value}
        disabled={isLoading}
        filterSelectedOptions
        onChange={(event: React.ChangeEvent<unknown>, newValue: PlaceType | null) => {
          setError('');
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          fetchAWSPlace(newValue?.place_id);
        }}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      {isLoading && (
        <LinearProgress
          classes={{
            colorPrimary: progressClasses.colorPrimary,
            barColorPrimary: progressClasses.barColorPrimary,
          }}
        />
      )}
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
    </div>
  );
}
