import React, { ReactElement, useEffect, useRef, useState, MutableRefObject } from 'react';
import { IPageLocationFields, IComponentDoctorFinderWithOpenMapFields } from 'types/contentful';

import { PlaceType } from 'components/UI/LocationAutocomplete';
import NoResults from 'components/UI/NoResults';
import { addItemToLocalStorage, getDistance } from 'lib/util';
import NewLocationCard from 'components/UI/NewLocationCard';
import SearchBar from 'components/UI/SearchBar';
import NewMap from 'components/UI/NewMap';
import AccordianBanner from 'components/UI/AccordianBanner';

const DEFAULT_RADIUS = 100;
const DEFAULT_LOCATION_ZOOM = 10;

const SAVED_LOCATION_KEY = 'finder-saved-location';
const SAVED_RADIUS_KEY = 'finder-saved-radius';
const SAVED_MOBILE_MAP_VIEW = 'saved-mobile-view';

export type Location = IPageLocationFields & {
  currentDistance?: number;
  sysId?: string;
  current?: boolean;
};

type MapCoord = {
  lat: number;
  lng: number;
};

export type UserLocation = {
  map: MapCoord;
  place?: PlaceType;
};

export interface DoctorFinderWithOpenMapProps extends IComponentDoctorFinderWithOpenMapFields {
  locations: Location[];
  markerPinIcon?: string;
  hideEyeIcon?: boolean;
  defaultUserLocation?: UserLocation | null;
}

export default function DoctorFinderWithOpenMap({
  heading,
  subHeading,
  description,
  site,
  locations,
  markerPinIcon = 'Clarkson-style Eye',
  hideEyeIcon = true,
  defaultUserLocation = null,
}: DoctorFinderWithOpenMapProps): ReactElement {
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS);
  const [userLocation, setUserLocation] = useState<UserLocation | null>(defaultUserLocation);
  const [foundLocations, setFoundLocations] = useState(locations);
  const [visibleLocations, setVisibleLocations] = useState<Location[] | null>(null);
  const [showPopUp, setShowPopUp] = useState<boolean>(true);
  const [activeLocation, setActiveLocation] = useState<Location | null>(null);
  const [searched, setSearched] = useState(false);
  const [showMapView, setShowMapView] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showMore, setShowMore] = useState<string>('Less');

  const mapInstance = useRef() as MutableRefObject<google.maps.Map>;
  const mapContainer = useRef() as MutableRefObject<HTMLDivElement>;

  const setInitialSearchPosition = (userLocation: UserLocation | null): void => {
    if (!mapInstance.current || userLocation === null) return undefined;
    mapInstance.current.setCenter(
      new google.maps.LatLng(userLocation.map.lat, userLocation.map.lng)
    );
    mapInstance.current.setZoom(DEFAULT_LOCATION_ZOOM);
  };

  useEffect(() => {
    if (window.innerWidth >= 640) {
      setVisibleLocations(locations);
    } else {
      setShowMore('More');
    }
  }, [locations]);

  /**
   * Sets initial zoom/position when a user location is selected.
   */
  useEffect(() => {
    setInitialSearchPosition(userLocation);
  }, [userLocation]);

  /**
   * When radius or user's selected location changes,
   * find all locations within range and sort.
   */
  useEffect(() => {
    if (userLocation === null) {
      setFoundLocations(locations);
      return;
    }

    const nearby = locations
      .map((loc) => {
        const coords = loc.map;
        const distance = getDistance(
          userLocation.map.lat,
          userLocation.map.lng,
          coords.lat,
          coords.lon
        );

        return { ...loc, currentDistance: distance };
      })
      .filter((loc) => loc.currentDistance <= radius)
      .sort((a, b) => a.currentDistance - b.currentDistance);

    if (nearby.length) {
      addItemToLocalStorage(SAVED_LOCATION_KEY, JSON.stringify(userLocation));
      addItemToLocalStorage(SAVED_RADIUS_KEY, radius.toString());
    }

    setRadius(radius);
    setActiveLocation(nearby[0]);
    setVisibleLocations(nearby);
    setFoundLocations(nearby);
    setSearched(true);
  }, [locations, radius, userLocation]);

  const toggleAccordion = (): void => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (showMore == 'Less') {
      setVisibleLocations(foundLocations);
    } else {
      setVisibleLocations(foundLocations.slice(0, 3));
    }
  }, [showMore, foundLocations]);
  /**
   * Get location from autocomplete and geocode it.
   * @param place
   * @returns
   */
  const locationSelected = (place: PlaceType): void => {
    if (place === null) return undefined;

    setUserLocation({ place, map: { lat: place.lat, lng: place.lng } });
  };

  const onUseMyLocation = (coords: GeolocationCoordinates): void => {
    setUserLocation({ map: { lat: coords.latitude, lng: coords.longitude } });
  };

  /**
   * Reset map data on show map view toggle
   */
  const toggleMapView = (showMapView: boolean): void => {
    if (showMapView) {
      setShowPopUp(true);
      setActiveLocation(null);
    }
    addItemToLocalStorage(SAVED_MOBILE_MAP_VIEW, showMapView.toString());
    setShowMapView(showMapView);
  };

  return (
    <>
      <SearchBar
        id={'1'}
        heading={heading}
        locationSelected={locationSelected}
        onUseMyLocation={onUseMyLocation}
        initValue={userLocation?.place}
        freshPaintToken={site?.fields.freshPaintToken}
        subHeading={subHeading}
        description={description}
      />

      {/* TOGGLE MAP VIEW BUTTON */}
      <div className="block lg:hidden flex justify-end mb-2 ">
        <ViewToggleLink
          label="Map View"
          isActive={showMapView}
          onClick={() => {
            setIsOpen(true);
            toggleMapView(true);
          }}
        />
        {'  |  '}
        <ViewToggleLink
          label="List View"
          isActive={!showMapView}
          onClick={() => {
            setIsOpen(true);
            toggleMapView(false);
          }}
        />
      </div>

      {/* LIST VIEW */}
      <div className="flex flex-col lg:flex-row-reverse pb-10">
        {/* MAP */}
        <div className="w-full lg:w-3/5">
          <div
            className={`h-hero-mobile lg:h-map relative relative ${
              showMapView ? 'block' : 'hidden lg:block'
            }`}
            ref={mapContainer}
            style={{
              height: showMapView ? (window.innerWidth < 1024 ? '350px' : '700px') : '',
            }}
          >
            <NewMap
              userLocation={userLocation}
              foundLocations={visibleLocations ?? foundLocations}
              showPopUp={showPopUp}
              activeLocation={activeLocation}
              setActiveLocation={setActiveLocation}
              renderInfoCard={'MapCard'}
              iconDetails={{
                hideEyeOnPin: hideEyeIcon,
                markerIcon: markerPinIcon,
                classNames: 'bg-grey',
              }}
              mapOptions={{ fullscreenControl: true, zoomControl: true, unBlockOnClick: true }}
              defaultCenter={{ lat: 37.7749, lng: -122.4194 }} // Default center on San Francisco
            />
          </div>
        </div>
        <AccordianBanner
          className="lg:hidden"
          onClick={toggleAccordion}
          isOpen={isOpen}
          header="Locations"
        />
        <div className="block lg:w-2/5 ">
          {isOpen ? (
            visibleLocations && visibleLocations.length ? (
              <div className=" h-auto lg:h-map sm:overflow-y-auto divide-y-2 lg:p-2">
                {visibleLocations.map(
                  (location): ReactElement => (
                    <div
                      key={`office-location-${location.slug}`}
                      className="transition ease-linear duration-100 shadow-none"
                      onFocus={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onBlur={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                      onMouseOver={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onMouseLeave={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                      onTouchStart={() => {
                        setActiveLocation(location);
                        setShowPopUp(false);
                      }}
                      onTouchCancel={() => {
                        setActiveLocation(null);
                        setShowPopUp(true);
                      }}
                    >
                      <NewLocationCard location={location} activeLocation={activeLocation} />
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center content-center">
                <NoResults />
                <p className="ml-5 text-center flex flex-col justify-center">
                  {searched && foundLocations.length === 0 && (
                    <>
                      <span className="font-bold">
                        Whoops,
                        <br />
                        no results found in your area
                      </span>
                      <br />
                      Please try a different search location.
                    </>
                  )}
                  {!searched && <span className="font-bold">Type above to start a search.</span>}
                </p>
              </div>
            )
          ) : null}
          {isOpen && foundLocations.length > 3 ? (
            <AccordianBanner
              className={'lg:hidden'}
              onClick={() => {
                if (showMore == 'More') {
                  setShowMore('Less');
                } else {
                  setShowMore('More');
                }
              }}
              header={`${showMore} Locations`}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

const ViewToggleLink = ({
  label,
  isActive,
  onClick,
}: {
  label: string;
  isActive: boolean;
  onClick: () => void;
}): ReactElement => (
  <a
    tabIndex={0}
    role="button"
    className={`mx-2 ${isActive ? 'text-secondary-actual' : ''}`}
    onClick={onClick}
    onKeyDown={onClick}
  >
    {label}
  </a>
);
