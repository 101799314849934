import { ReactElement } from 'react';

export default function NoResults(): ReactElement {
  return (
    <svg
      width="125"
      height="126"
      viewBox="0 0 125 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0 75.3657C0 79.9375 1.35571 84.4067 3.89569 88.208C6.43568 92.0094 10.0459 94.9722 14.2697 96.7218C18.4935 98.4713 23.1413 98.9291 27.6253 98.0372C32.1093 97.1453 36.2281 94.9437 39.4609 91.7109C42.6937 88.4781 44.8953 84.3593 45.7872 79.8753C46.6791 75.3913 46.2213 70.7435 44.4718 66.5197C42.7222 62.2958 39.7594 58.6857 35.9581 56.1457C32.1567 53.6057 27.6875 52.25 23.1157 52.25C16.985 52.25 11.1055 54.6854 6.77042 59.0204C2.43539 63.3555 0 69.235 0 75.3657"
          fill="white"
        />
        <path
          d="M87.2148 63.583C87.2148 67.2602 88.3053 70.855 90.3483 73.9125C92.3913 76.9701 95.2951 79.3531 98.6924 80.7604C102.09 82.1676 105.828 82.5358 109.435 81.8184C113.041 81.101 116.354 79.3302 118.955 76.73C121.555 74.1298 123.326 70.8168 124.043 67.2102C124.76 63.6036 124.392 59.8652 122.985 56.4678C121.578 53.0705 119.195 50.1667 116.137 48.1237C113.08 46.0807 109.485 44.9902 105.808 44.9902C103.366 44.9893 100.947 45.4695 98.6913 46.4035C96.4351 47.3375 94.385 48.707 92.6583 50.4337C90.9316 52.1604 89.5622 54.2104 88.6281 56.4667C87.6941 58.7229 87.2139 61.141 87.2148 63.583Z"
          fill="#003140"
        />
        <path
          d="M52.0176 104.858C52.0161 108.951 53.2285 112.953 55.5014 116.357C57.7743 119.761 61.0057 122.414 64.7868 123.981C68.5679 125.549 72.7289 125.96 76.7435 125.162C80.7582 124.364 84.4461 122.394 87.3409 119.5C90.2357 116.607 92.2073 112.92 93.0063 108.905C93.8054 104.891 93.396 100.73 91.83 96.948C90.264 93.1663 87.6117 89.934 84.2085 87.6599C80.8053 85.3857 76.8041 84.1719 72.711 84.1719C69.9939 84.1709 67.3032 84.7052 64.7926 85.7444C62.282 86.7835 60.0007 88.3071 58.079 90.228C56.1573 92.149 54.633 94.4297 53.5929 96.94C52.5529 99.4502 52.0176 102.141 52.0176 104.858Z"
          fill="#34BCCD"
        />
        <path
          d="M10.9668 19.438C10.9653 21.7763 11.6575 24.0625 12.9556 26.0074C14.2538 27.9522 16.0997 29.4683 18.2597 30.3638C20.4197 31.2593 22.7969 31.494 25.0903 31.0382C27.3838 30.5824 29.4905 29.4566 31.1439 27.8031C32.7974 26.1497 33.9232 24.043 34.379 21.7495C34.8349 19.4561 34.6002 17.0789 33.7047 14.9189C32.8091 12.7589 31.293 10.913 29.3482 9.61481C27.4033 8.31663 25.1171 7.6245 22.7788 7.62598C19.6461 7.62598 16.6416 8.87046 14.4265 11.0856C12.2113 13.3008 10.9668 16.3053 10.9668 19.438Z"
          fill="#FDDA00"
        />
        <path
          d="M56.3096 17.1125C56.3081 20.4991 57.3111 23.8101 59.1917 26.6266C61.0723 29.4431 63.746 31.6386 66.8745 32.9353C70.0031 34.232 73.446 34.5717 76.7676 33.9113C80.0893 33.251 83.1404 31.6203 85.5352 29.2256C87.9299 26.8309 89.5605 23.7797 90.2209 20.4581C90.8812 17.1364 90.5416 13.6935 89.2449 10.565C87.9482 7.43641 85.7527 4.76271 82.9362 2.88212C80.1197 1.00152 76.8087 -0.00147693 73.422 1.63235e-06C68.8835 1.63235e-06 64.5309 1.80292 61.3217 5.01213C58.1125 8.22134 56.3096 12.574 56.3096 17.1125Z"
          fill="#34BCCD"
        />
        <path
          d="M80.0052 88.7586C91.8323 76.9315 91.8323 57.756 80.0052 45.9289C68.1781 34.1019 49.0026 34.1019 37.1756 45.9289C25.3485 57.756 25.3485 76.9315 37.1756 88.7586C49.0026 100.586 68.1781 100.586 80.0052 88.7586Z"
          stroke="#1F185C"
          strokeWidth="0.770024"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="124.4" height="125.544" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
