import { ReactElement, useState, useEffect } from 'react';
import { PlaceType } from './LocationAutocomplete';
import NewLocationAutocomplete from './NewLocationAutocomplete';
import CTA from './Cta';
import { IComponentDoctorFinderFields } from 'types/contentful';
import RichText from './Richtext';

interface SearchHeroProps extends IComponentDoctorFinderFields {
  locationSelected(place: PlaceType | null): void;
  onUseMyLocation(coords: GeolocationCoordinates): void;
  initValue?: PlaceType | null;
  freshPaintToken: string | null | undefined;
  className?: string;
  subHeading?: string | undefined;
}

export default function SearchBar({
  locationSelected,
  onUseMyLocation,
  initValue,
  freshPaintToken,
  className,
  description,
  ctA,
  heading,
  subHeading,
  backgroundColor,
}: SearchHeroProps): ReactElement {
  const [background, setBackground] = useState<string>('bg-white');

  useEffect(() => {
    if (backgroundColor && backgroundColor === 'Muted') {
      setBackground('bg-muted');
    }
  }, [backgroundColor]);

  return (
    <div className={`p-10 text-center space-y-6 text-primary-actual ${className} ${background}`}>
      <div>
        <h5 className="text-3xl md:text-4xl text-primary-actual">{heading}</h5>
        {subHeading && (
          <p className="text-2xl md:text-3xl font-light mt-2 md:px-8 mx-8 ">{subHeading}</p>
        )}
      </div>
      <div className="flex flex-col w-full md:w-3/4 mx-auto">
        {
          <NewLocationAutocomplete
            className={`${background}`}
            onSelect={locationSelected}
            onUseMyLocation={onUseMyLocation}
            initValue={initValue}
            freshPaintToken={freshPaintToken}
            hideEndAdornment={true}
          />
        }
      </div>
      {description && (
        <RichText
          document={description}
          classNames="text-lg text-primary-actual lg:text-2xl font-light px-2 md:px-6"
        />
      )}
      <div className="flex flex-row justify-between w-full sm:w-4/5 md:w-3/5 lg:w-1/2 sm:mx-auto">
        {ctA?.map((x, index) => (
          <div key={index} className="w-button mx-1">
            <CTA
              {...x.fields}
              classNames=" w-full flex flex-grow justify-center text-primary-actual button-text bg-white remove-cta-padding"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
